import React from "react"
import classes from "../styles/resume.module.scss"

function Resume() {
  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div>
          <h1>Paul Von Zimmerman</h1>
          <ul>
            <li>431 Broadway, Tacoma WA 98402</li>
            <li>(253) 514-9612</li>
            <li>
              <a href="mailto:vonzimp@gmail.com"> vonzimp@gmail.com</a>
            </li>
            <li>
              <a href="https://paulvonzimmerman.com">
                https://paulvonzimmerman.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.education}>
        <h2>Education</h2>
        <h3>Western Washington University (2013–2017)</h3>
        <h4>Design Major</h4>
        <p>
          Focused on UX, web design, and graphic design. Helped created the 2017
          graduate website.
        </p>
        <h4><b>Minor</b> in Liberal Studies</h4>
        <p>Humanities degree focusing on the study of cultural history.</p>
        <h4>Minor in Computer Science</h4>
        <p>
          Studied algorithms, theory of computation, and relational databases.
          Programmed in Java, Python, and Racket (a dialect of Lisp), and SQL.
        </p>
        <h3>Tacoma Community College (2011–2013)</h3>
        <p>Associates Degree in Arts and Science.</p>
      </div>

      <div className={classes.experience}>
        <h2>Experience</h2>
        <h3>Bargreen-Ellingson Lead Front-End Developr</h3>
        <p>
          Designed, developed, and maintained applications written in Angular 2+
          and React.
        </p>
        <h3>WWU ResTek Web & Print Designer (2014–2017)</h3>
        <p>Collaborated with peers to create mockups for web applications.</p>
        <p>
          Implemented new pages and interfaces for web apps using the Django
          templating language and HTML, CSS, and JavaScript.
        </p>
        <p>Helped recruit and train new employees.</p>
        <h3>ResTek Consultant (2013–2014)</h3>
        <p>Worked with residents and troubleshooted technical issues.</p>
      </div>
      <div className={classes.skills}>
        <h2> Skills </h2>
        <ul>
          <li>Adobe Illustrator</li>
          <li>React</li>
          <li>JavaScript</li>
          <li>Adobe Photoshop</li>
          <li>Typescript</li>
          <li>HTML/CSS</li>
          <li>Angular 2+</li>
          <li>Python</li>
          <li>Webpack</li>
        </ul>
      </div>
    </div>
  )
}

export default Resume
